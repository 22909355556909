import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import {
  CRow
} from '@coreui/react'
import { isError } from "lodash";

const ShowPaymentDetail = (props) => {
  let value = 0;
  let componentRef = useRef();

  if ("Empty" in props.res) {
    return (
      <>
      </>
    )
  } else {

    return (
<>

{/* <button onClick={handleClick}>Check</button> */}

        <div>
          <ReactToPrint
            trigger={() => <PrintIcon style={{ cursor: "pointer" }} />}
            content={() => componentRef}
          />
        </div>

      <CRow  id="print"
      // style={{ font: '"Open Sans",sans-serif', fontSize: '11px', lineHeight: '10px', width: '900px', marginLeft: 'auto', marginRight: 'auto', overflow: "auto", height: "255px" }}
      >
<div ref={(el) => (componentRef = el)} class = "table-responsive" >
        <table  border={0} style={{ marginLeft: '10px',marginTop: '15px', font: '"Courier New", Courier, monospace', fontSize: '11px', lineHeight: '10px', width: '900px' }}>
          <tbody><tr>
            <td width="500px"><strong>{props.res.NewDataSet.ROOT.PayerName}</strong></td>
            <td> <strong>{props.res.NewDataSet.ROOT.PayeeName}</strong></td>
          </tr>
            <tr>
              <td>{props.res.NewDataSet.ROOT.PayerAddress}</td>
              <td>{props.res.NewDataSet.ROOT.PayeeAddress}</td>
            </tr>
            <tr>
              <td>{props.res.NewDataSet.ROOT.PayerCity} , {props.res.NewDataSet.ROOT.PayerState} &nbsp; {props.res.NewDataSet.ROOT.PayerZipcode}</td>
              <td>{props.res.NewDataSet.ROOT.PayeeCity} , {props.res.NewDataSet.ROOT.PayeeState} &nbsp; {props.res.NewDataSet.ROOT.PayeeZipcode}</td>
            </tr>
            <tr>
              <td> </td>
              <td valign="top">
                <br /><strong>NPI #:</strong>&nbsp;&nbsp;&nbsp;{props.res.NewDataSet.ROOT.PayeeNpi}<br /></td>
            </tr>
            <tr>
              <td>
                <br />
                <strong>PAYER BUSINESS CONTACT INFORMATION:</strong>
              </td>
              <td valign="top">
                <br />
                <strong>CHECK/EFT #:&nbsp;&nbsp;&nbsp;</strong>{props.res.NewDataSet.ROOT.CheckNum}</td>
            </tr>
            <tr>
              <td>  <br /></td>

              <td>
                <br />
                <strong>CHECK AMT:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{props.res.NewDataSet.ROOT.CheckAmt}</td>
            </tr>
            <tr>
              <td>
                <br />
                <strong>PAYER TECHNICAL CONTACT INFORMATION: </strong></td>
              <td>
                <br />
                <strong>CHECK DATE:</strong>&nbsp;&nbsp;&nbsp;{props.res.NewDataSet.ROOT.CheckDate}</td>
            </tr>
            <tr>
              <td colSpan={2}><br /></td>

            </tr>

          </tbody></table>

            {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? Array.isArray(props.res.NewDataSet.ROOT.CLAIM_RECORD) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.map((obj) => {
              value = 0;
              return (
            <>
            <br/>
              <table style={{ marginLeft: '10px',backgroundColor: '#b7c8e1', lineHeight: '10px', fontSize: '11px', width: '897px', height: '40px' }} border={0}>
               <tbody >
                  <tr style={{height: '20px' }}>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '78.6562px' }}><strong>REND-PROV</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '62.9531px' }}> <strong>SERV-DATE</strong> </td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '62.9531px' }}><strong>POS</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '104.438px' }}><strong>PD-PROC/MODS</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '49.3594px' }}><strong>PD-NOS</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '114.234px' }}><strong>BILLED</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '123.656px' }}><strong>ALLOWED</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '97.4844px' }}><strong>DEDUCT</strong></td>
                    <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '89.125px' }}><strong>COINS</strong></td>
                    <td style={{ height: '20px', width: '53.1406px' }}><strong>PROV-PD</strong></td>
                  </tr>
                  <tr style={{ height: '20px' }}>
                    <td style={{ height: '20px', width: '327px' }} colSpan={4}>RARC</td>
                    <td style={{ height: '20px', width: '49.3594px' }}>SUB-NOS</td>
                    <td style={{ height: '20px', width: '114.234px' }}>SUB-PROC</td>
                    <td style={{ height: '20px', width: '123.656px' }}>GRP/CARC</td>
                    <td style={{ height: '20px', width: '97.4844px' }}>CARC-AMT</td>
                    <td style={{ height: '20px', width: '148.266px' }} colSpan={2}>ADJ-QTY</td>
                  </tr>
                </tbody>
              </table>
              <table style={{marginLeft:'10px', font: '"Open Sans",sans-serif', fontSize: '11px', lineHeight: '10px', width: '900px' }} border={0}>
                <tbody>
                  <tr style={{ backgroundColor: '#ffffff', color: '#000000' }}>
                    <td colSpan={10}><hr /></td>
                  </tr>
                  <tr style={{ backgroundColor: '#e4e8f3', color: '#000000' }}>

                    <td colSpan={3}><strong>NAME</strong>:&nbsp;{obj.PatientName}</td>
                    <td><strong>HIC</strong>:&nbsp;{obj.PolicyNum}</td>
                    <td colSpan={2}><strong>ACNT</strong>:&nbsp;{obj.Clp01ClaimNum}</td>
                    <td><strong>ICN</strong>:&nbsp;{obj.InsClaimNum}</td>
                    <td colSpan={3}><strong>ASG</strong>:&nbsp;{obj.Asg}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>MOA</strong>:&nbsp;{obj.Moa}</td>

                  </tr>
                  
                  {Array.isArray(obj.CHARGE_RECORD) ? obj.CHARGE_RECORD.map((obj1) => {

                    return (

                      <>
                        <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>

                          <td width="83px">&nbsp;{obj1.RendNpi}</td>
                          <td width="60px">{obj1.DosFrom} {obj1.DosTo} </td>
                          <td width="60px">&nbsp;</td>
                          <td width="88px">&nbsp;{obj1.CptMod}</td>
                          <td width="89px">&nbsp;{obj1.PaidUnits}</td>
                          <td width="109px"> {obj1.ChargeAmt}</td>
                          <td width="118px"> {obj1.AllowedAmt}</td>
                          <td width="93px">{obj1.Deduct}</td>
                          <td width="85px"> {obj1.Coins}</td>
                          <td width="50px"> {obj1.PaidAmt}</td>
                        </tr>
                        
                        <tr>
                          <td width="83px">&nbsp; {obj1.RemarkCode}</td>
                          <td>
                            <div style={{ verticalAlign: 'text-bottom' }}><strong>CNTL</strong>:&nbsp;#:&nbsp;: {obj1.ChargeRef6r}</div>
                          </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          
                          <td style={{ backgroundColor: '#c0c0c0' }}>
                            {Array.isArray(obj1.REMIT_CODE) ? obj1.REMIT_CODE.map((remitObj) => {

                              return (

                                <>
                                  {remitObj.ADJ_CODE} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {remitObj.ADJ_AMT} <br />
                                </>
                                
                              )
                            }):
                              
                              <>
                                {(obj1.REMIT_CODE?.hasOwnProperty('ADJ_CODE')) ? obj1.REMIT_CODE.ADJ_CODE : ""} 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                {(obj1.REMIT_CODE?.hasOwnProperty('ADJ_AMT')) ? obj1.REMIT_CODE.ADJ_AMT : ""} <br />

                              </>
                              
                            }
                          </td>

                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        
                      </>
                    )
                  }) :

                    <>
                      <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>

                        <td width="83px">&nbsp;{obj.CHARGE_RECORD.RendNpi}</td>
                        <td width="60px">{obj.CHARGE_RECORD.DosFrom} &nbsp;&nbsp;&nbsp;{obj.CHARGE_RECORD.DosTo} </td>
                        <td width="60px">&nbsp;</td>
                        <td width="88px">&nbsp;{obj.CHARGE_RECORD.CptMod}</td>
                        <td width="89px">&nbsp;{obj.CHARGE_RECORD.PaidUnits}</td>
                        <td width="109px"> {obj.CHARGE_RECORD.ChargeAmt}</td>
                        <td width="118px"> {obj.CHARGE_RECORD.AllowedAmt}</td>
                        <td width="93px">{obj.CHARGE_RECORD.Deduct}</td>
                        <td width="85px"> {obj.CHARGE_RECORD.Coins}</td>
                        <td width="50px"> {obj.CHARGE_RECORD.PaidAmt}</td>
                      </tr>
                      
                      <tr>
                        <td width="83px">&nbsp; {obj.CHARGE_RECORD.RemarkCode}</td>
                        <td>
                          <div style={{ verticalAlign: 'text-bottom' }}><strong>CNTL</strong>:&nbsp;#:&nbsp;: {obj.CHARGE_RECORD.ChargeRef6r}</div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>

                        <td style={{ backgroundColor: '#c0c0c0' }}>
                          {Array.isArray(obj.CHARGE_RECORD.REMIT_CODE) ? obj.CHARGE_RECORD.REMIT_CODE.map((remitObj) => {
                            return (

                              <>
                                {remitObj.ADJ_CODE} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {remitObj.ADJ_AMT} <br />
                              </>
                            )
                          }) :

                            <>
                              {(obj.CHARGE_RECORD.REMIT_CODE?.hasOwnProperty('ADJ_CODE')) ? obj.CHARGE_RECORD.REMIT_CODE.ADJ_CODE : ""} 
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              {(obj.CHARGE_RECORD.REMIT_CODE?.hasOwnProperty('ADJ_AMT')) ? obj.CHARGE_RECORD.REMIT_CODE.ADJ_AMT : ""} <br />

                            </>
                          }
                        </td>


                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>


                      {/* <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>
                        <td>&nbsp;</td>
                        <td colSpan={2}> <strong>PT RESP</strong>&nbsp;&nbsp;&nbsp; {obj.PatResp} </td>
                        <td>CARC&nbsp;&nbsp; {obj.TotalAdjustmentAmt}</td>

                        <td colSpan={2}><strong>CLAIM TOTALS</strong>&nbsp;&nbsp; {obj.TotalBilled}</td>

                        <td> {obj.TotalAllowed}</td>
                        <td> {obj.TotalDed}</td>
                        <td> {obj.TotalCoins}</td>
                        <td> {obj.TotalPaid}</td>
                      </tr>

                      <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>
                        <td>ADJ TO TOTALS:</td>
                        <td>PREV PD&nbsp;&nbsp; {obj.PreviousPaid}</td>
                        <td>INTEREST&nbsp; {obj.Interest}</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td colSpan={2}>LATE FILING CHARGE&nbsp;</td>

                        <td>&nbsp;{obj.LateFeeCharge}</td>
                        <td>
                          <div style={{ marginLeft: '9px' }}><strong>NET</strong>&nbsp; {obj.NetPaid}</div>
                        </td>
                      </tr> */}

                      {/* <tr style={{ backgroundColor: '#b7c8e1', color: '#000000' }}>
                        <td colSpan={3} valign="top"><strong>OTHER CLAIM REL IDENTIFICATION:</strong></td>
                        <td colSpan={7}>
                          {props.res.NewDataSet.ROOT.CLAIM_RECORD.hasOwnProperty("CLAIM_REF_INFO") ? Array.isArray(obj.CLAIM_REF_INFO) ? obj.CLAIM_REF_INFO.map((claimObj) => {

                            return (

                              <>
                                {!claimObj.REF_01=="undefined"?claimObj.REF_01:''}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {claimObj.REF_02}<br />
                              </>
                            )
                          }) :
                            <>
                              {!obj.CLAIM_REF_INFO.REF_01=="undefined"?obj.CLAIM_REF_INFO.REF_01:''}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {obj.CLAIM_REF_INFO.REF_02}<br />
                            </>
:''
                          }
                        </td>
                      </tr>

                      <tr style={{ backgroundColor: '#b7c8e1', color: '#000000' }}>
                        <td colSpan={3} valign="top"><strong>CLAIM LEVEL ADJUSTMENT CODES:</strong></td>
                        <td colSpan={7}>
                          {Array.isArray(obj.CLAIM_REMIT_CODE) ? obj.CLAIM_REMIT_CODE.map((remitObj) => {

                            return (

                              <>
                                {remitObj.ADJ_CODE}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {remitObj.ADJ_AMT}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </>
                            )
                          }) :

                            <>
                              {(obj.hasOwnProperty('CLAIM_REMIT_CODE')) ? obj.CLAIM_REMIT_CODE.ADJ_CODE : ""}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {(obj.hasOwnProperty('CLAIM_REMIT_CODE')) ? obj.CLAIM_REMIT_CODE.ADJ_AMT : ""}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                          }
                        </td>
                      </tr> */}

                    </>
                  }
                  {/* Jawad */}
                  <tr>&nbsp;</tr>
                  <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>
                    <td>&nbsp;</td>
                    <td colSpan={2}> <strong>PT RESP</strong>&nbsp;&nbsp;&nbsp; {obj.PatResp} </td>
                    <td>CARC&nbsp;&nbsp; {obj.TotalAdjustmentAmt}</td>

                    <td colSpan={2}><strong>CLAIM TOTALS</strong>&nbsp;&nbsp; {obj.TotalBilled}</td>

                    <td> {obj.TotalAllowed}</td>
                    <td> {obj.TotalDed}</td>
                    <td> {obj.TotalCoins}</td>
                    {/* <td> {obj.TotalPaid}</td> */}
                  </tr>

                  <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>
                    <td>ADJ TO TOTALS:</td>
                    <td>PREV PD&nbsp;&nbsp; {obj.PreviousPaid}</td>
                    <td>INTEREST&nbsp; {obj.Interest}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td colSpan={2}>LATE FILING CHARGE&nbsp;</td>

                    <td>{obj.LateFeeCharge}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td>
                      <div><strong>NET&nbsp;</strong>{obj.NetPaid}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
            
          )
        }) :
          <table style={{ backgroundColor: '#b7c8e1', lineHeight: '10px', fontSize: '11px', width: '900px', height: '40px' }} border={0}>
            <tbody>
              <tr style={{ height: '20px' }}>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '78.6562px' }}><strong>REND-PROV</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '62.9531px' }}> <strong>SERV-DATE</strong> </td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '62.9531px' }}><strong>POS</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '104.438px' }}><strong>PD-PROC/MODS</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '49.3594px' }}><strong>PD-NOS</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '114.234px' }}><strong>BILLED</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '123.656px' }}><strong>ALLOWED</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '97.4844px' }}><strong>DEDUCT</strong></td>
                <td style={{ borderRight: '1px solid #99a1aa', height: '20px', width: '89.125px' }}><strong>COINS</strong></td>
                <td style={{ height: '20px', width: '53.1406px' }}><strong>PROV-PD</strong></td>
              </tr>
              <tr style={{ height: '20px' }}>
                <td style={{ height: '20px', width: '327px' }} colSpan={4}>RARC</td>
                <td style={{ height: '20px', width: '49.3594px' }}>SUB-NOS</td>
                <td style={{ height: '20px', width: '114.234px' }}>SUB-PROC</td>
                <td style={{ height: '20px', width: '123.656px' }}>GRP/CARC</td>
                <td style={{ height: '20px', width: '97.4844px' }}>CARC-AMT</td>
                <td style={{ height: '20px', width: '148.266px' }} colSpan={2}>ADJ-QTY</td>
              </tr>
          
            </tbody>
                <td style={{ backgroundColor: '#ffff'}} colSpan={10}><hr /></td>

                <tr style={{ backgroundColor: '#e4e8f3', color: '#000000' }}>

                  <td colSpan={3}><strong>NAME</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.PatientName : ""}</td>
                  <td><strong>HIC</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.PolicyNum : ""}</td>
                  <td colSpan={2}><strong>ACNT</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Clp01ClaimNum : ""}</td>
                  <td><strong>ICN</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.InsClaimNum : ""}</td>
                  <td colSpan={3}><strong>ASG</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Asg : ""}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>MOA</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Moa : ""}</td>

                </tr>
          </table>

         :  ""} 

        <table style={{ font: '"Open Sans",sans-serif', fontSize: '11px', lineHeight: '10px', width: '900px' }} border={0}>
        <tbody>
          <tr style={{ backgroundColor: '#ffffff', color: '#000000' }}>
            {/* <td colSpan={10}><hr /></td> */}
          </tr>
          
          {value == 0 ? "" :
           <tr style={{ backgroundColor: '#e4e8f3', color: '#000000' }}>
            
            <td colSpan={3}><strong>NAME</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.PatientName : ""}</td>
            <td><strong>HIC</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.PolicyNum : ""}</td>
            <td colSpan={2}><strong>ACNT</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Clp01ClaimNum : ""}</td>
            <td><strong>ICN</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.InsClaimNum : ""}</td>
            <td colSpan={3}><strong>ASG</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Asg : ""}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>MOA</strong>:&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Moa : ""}</td>

          </tr>
  }
          {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.hasOwnProperty("CHARGE_RECORD") ? Array.isArray(props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.map((obj) => {
            value = 1;
            return (
              
              <>
                <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>

                  <td width="83px">&nbsp;{obj.RendNpi}</td>
                  <td width="60px">{obj.DosFrom} {obj.DosTo} </td>
                  <td width="60px">&nbsp;</td>
                  <td width="88px">&nbsp;{obj.CptMod}</td>
                  <td width="89px">&nbsp;{obj.PaidUnits}</td>
                  <td width="109px"> {obj.ChargeAmt}</td>
                  <td width="118px"> {obj.AllowedAmt}</td>
                  <td width="93px">{obj.Deduct}</td>
                  <td width="85px"> {obj.Coins}</td>
                  <td width="50px"> {obj.PaidAmt}</td>
                </tr>
                <tr>
                  <td width="83px">&nbsp; {obj.RemarkCode}</td>
                  <td>
                    <div style={{ verticalAlign: 'text-bottom' }}><strong>CNTL</strong>:&nbsp;#:&nbsp;: {obj.ChargeRef6r}</div>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>

                  <td style={{ backgroundColor: '#c0c0c0' }}>
                    {Array.isArray(obj.REMIT_CODE) ? obj.REMIT_CODE.map((remitObj) => {

                      return (

                        <>
                          {remitObj.ADJ_CODE} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {remitObj.ADJ_AMT} <br />
                        </>
                      )
                    }) :

                      <>
                      {/* {obj.REMIT_CODE.ADJ_AMT} */}
                        {obj.hasOwnProperty("REMIT_CODE") ? null : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {obj.hasOwnProperty("REMIT_CODE") ? null : ""}  <br />
                      </>
                    }
                  </td>


                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>

              </>
            )
          }) :

            <>
              <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>

                <td width="83px">&nbsp;{props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.RendNpi}</td>
                <td width="60px">{props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.DosFrom} &nbsp;&nbsp;&nbsp;{props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.DosTo} </td>
                <td width="60px">&nbsp;</td>
                <td width="88px">&nbsp;{props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.CptMod}</td>
                <td width="89px">&nbsp;{props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.PaidUnits}</td>
                <td width="109px"> {props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.ChargeAmt}</td>
                <td width="118px"> {props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.AllowedAmt}</td>
                <td width="93px">{props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.Deduct}</td>
                <td width="85px"> {props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.Coins}</td>
                <td width="50px"> {props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.PaidAmt}</td>
              </tr>
              <tr>
                <td width="83px">&nbsp; {props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.RemarkCode}</td>
                <td>
                  <div style={{ verticalAlign: 'text-bottom' }}><strong>CNTL</strong>:&nbsp;#:&nbsp;: {props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.ChargeRef6r}</div>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>

                <td style={{ backgroundColor: '#c0c0c0' }}>
                  {Array.isArray(props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.REMIT_CODE) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.REMIT_CODE.map((remitObj) => {

                    return (

                      <>
                        {remitObj.ADJ_CODE} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {remitObj.ADJ_AMT} <br />
                      </>
                    )
                  }) :

                    <>
                      {(props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.REMIT_CODE?.hasOwnProperty('ADJ_CODE')) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.REMIT_CODE.ADJ_CODE : ""} 
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                      {(props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.REMIT_CODE?.hasOwnProperty('ADJ_AMT')) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CHARGE_RECORD.REMIT_CODE.ADJ_AMT : ""} <br />

                    </>
                  }
                </td>


                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              

            </>

          : "" : ""}
          {value == 0 ? "":
           <>
           <tr>&nbsp;</tr>
           <tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>
                    <td>&nbsp;</td>
                    <td colSpan={2}> <strong>PT RESP</strong>&nbsp; &nbsp; &nbsp; {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.PatResp : ""} </td>
                    <td>CARC&nbsp; &nbsp; {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.TotalAdjustmentAmt : ""}</td>

                    <td colSpan={2}><strong>CLAIM TOTALS</strong>&nbsp; &nbsp; {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.TotalBilled : ""}</td>

                    <td> {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.TotalAllowed : ""}</td>
                    <td> {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.TotalDed : ""}</td>
                    <td> {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.TotalCoins : ""}</td>
                    {/* <td> {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.TotalPaid : ""}</td> */}
                  </tr><tr style={{ backgroundColor: '#ffffff', color: '#000000', border: '!important' }}>
                      <td>ADJ TO TOTALS:</td>
                      <td>PREV PD&nbsp; &nbsp; {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.PreviousPaid : ""}</td>
                      <td>INTEREST&nbsp; {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.Interest : ""}</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td colSpan={2}>LATE FILING CHARGE&nbsp;</td>

                      <td>&nbsp;{props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.LateFeeCharge : ""}</td>
                      <td>
                        <div><strong>NET</strong>&nbsp; {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.NetPaid : ""}</div>
                      </td>
                    </tr>
                    </>
          }

          <tr style={{ backgroundColor: '#b7c8e1', color: '#000000' }}>
            <td colSpan={3} valign="top"><strong>OTHER CLAIM REL IDENTIFICATION:</strong></td>
            <td colSpan={7}>
              {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.hasOwnProperty("CLAIM_REF_INFO") ? Array.isArray(props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REF_INFO) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REF_INFO.map((claimObj) => {

                return (

                  <>
                    {!claimObj.REF_01=="undefined"?claimObj.REF_01:''}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {claimObj.REF_02}<br />
                  </>
                )
              }) :
                <>
                  {!props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REF_INFO.REF_01=="undefined"?props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REF_INFO.REF_01:''}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REF_INFO.REF_02}<br />
                </>

             : "" : "" }
            </td>
          </tr>

          <tr style={{ backgroundColor: '#b7c8e1', color: '#000000' }}>
            <td colSpan={3} valign="top"><strong>CLAIM LEVEL ADJUSTMENT CODES:</strong></td>
            <td colSpan={7}>
              {props.res.NewDataSet.ROOT.hasOwnProperty("CLAIM_RECORD") ? props.res.NewDataSet.ROOT.CLAIM_RECORD.hasOwnProperty("CLAIM_REMIT_CODE") ? Array.isArray(props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REMIT_CODE) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REMIT_CODE.map((remitObj) => {

                return (

                  <>
                    {remitObj.ADJ_CODE}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {remitObj.ADJ_AMT}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                )
              }) :

                <>
                  {(props.res.NewDataSet.ROOT.CLAIM_RECORD.hasOwnProperty('CLAIM_REMIT_CODE')) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REMIT_CODE.ADJ_CODE : ""}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {(props.res.NewDataSet.ROOT.CLAIM_RECORD.hasOwnProperty('CLAIM_REMIT_CODE')) ? props.res.NewDataSet.ROOT.CLAIM_RECORD.CLAIM_REMIT_CODE.ADJ_AMT : ""}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
             : "" : "" }
            </td>
          </tr>










        </tbody>
      </table>

        <table style={{ marginLeft: '10px' }}>

          <tbody>
            <tr>
              <td><strong> <br />GLOSSARY: </strong></td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <table style={{ marginLeft: '10px' }}>
          {props.res.NewDataSet.hasOwnProperty('GLOSSARY') ?  Array.isArray(props.res.NewDataSet.GLOSSARY) ? props.res.NewDataSet.GLOSSARY.map((obj) => {

            return (

              <>
                <tbody>
                  <tr>
                    <td width="100px">  {obj.Code} &nbsp; </td>
                    <td width="2000px">  {obj.Description}&nbsp; </td>
                  </tr>
                </tbody>
              </>

            )

          }) :


            <tbody>
              <tr>
                <td width="100px">  {props.res.NewDataSet.GLOSSARY.Code} &nbsp; </td>
                <td width="2000px">  {props.res.NewDataSet.GLOSSARY.Description}&nbsp; </td>
              </tr>
            </tbody>

           : ""}



        </table >
        </div>
      </CRow >
      </>
    );
  }
}


export default ShowPaymentDetail;
