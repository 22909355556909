import React, { useEffect, useState } from 'react'
import axios from 'src/containers/GlobalSettings/axios';
import { request } from 'src/containers/GlobalSettings/request';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import UserProfile from 'src/containers/GlobalSettings/UserProfile'
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import progressbar from 'src/views/progressbar/progressbar';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>

)
const TheLayout = React.lazy(() => import('src/containers/ThemeContainer/TheLayout'));


const PEHRLogin = (props) => {

    const [loginPEHR, setloginPEHR] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(true);

    let Redirect = window.location.href;
    let TempRedirect = Redirect.split('/');
    TempRedirect = TempRedirect[0] + "//" + TempRedirect[2] + "/";


    const useStyles = makeStyles((theme) => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
      }));
    
    const classes = useStyles();

    function getCredentials() {

        axios.get(`${request.Get.PracticeEHRSingleClick}?edi_client_id=${props.ClientID}&db=LiveDB`)
            .then(response => {
  
                if ("Empty" in response.data[2].data) {

                } else {
                    UserProfile.setAllowSendToPM(response.data[2].data[0].AllowSendToPm)
                }
                if ("Empty" in response.data[2].data) {

                } else {
                    UserProfile.setAllowRequeue(response.data[2].data[0].AllowRequeue)
                }
                UserProfile.setSeqnum(response.data[0].data[0].CLIENT_SEQ_NUM);
                // UserProfile.setName(response.data[3].data.username);
                UserProfile.setName(props.UserName);
                UserProfile.setWorkQueueCount("Empty" in response.data[1].data ? "0" : response.data[1].data[0])
                setLoading(false);
                setloginPEHR(false);
                setOpen(!open);
                // props.onchangestatus(false)
            })
            .catch(error => {
                setOpen(!open);
                setLoading(false);
                window.location.replace(TempRedirect +"?cfg=" +btoa('dataNotFound'));
               // error.message.includes("404") ? alert("EDI Portal not Found !") : alert(error.message);
            })

    }

    useEffect(() => {
        getCredentials();
    }, [])

    return (
        <BrowserRouter>
            <React.Suspense fallback={loading}>
                <Switch>
              
                    {!loginPEHR && <Route path="/" name="Home" render={props => <TheLayout />} />}
                    {isLoading && <Backdrop className={classes.backdrop} open={open} > {progressbar(1)} </Backdrop>}
                </Switch>
            </React.Suspense>
        </BrowserRouter>
        
    );
}

export default PEHRLogin
