import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import UserProfile from './containers/GlobalSettings/UserProfile';
import KendoPayerList from './views/PayerList/KendoPayerList';
import ERACustomPEHR from './views/Common/ERACustomPEHR';
import PEHRLogin from './views/PEHRLogin/PEHRLogin';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>

)

const TheLayout = React.lazy(() => import('./containers/ThemeContainer/TheLayout'));
const Login = React.lazy(() => import('./views/Login/Login'));

const App = () => {
  const [showlogin, setshowlogin] = useState(true);
  const [webPayerList, setwebPayerList] = useState(false);
  const [ERAViewList, setERAViewList] = useState(false);
  const [loginPEHR, setloginPEHR] = useState(false);
  const [ediClientID, setediClientID] = useState('');
  const [pehrUserName, setPehrUserName] = useState('');
  const [errorSpan, setErrorSpan] = useState(false);
  var params = new URLSearchParams(window.location.search)
  useEffect(() => {
    UserProfile.removeItem();
    let Redirect = window.location.href;
    let TempRedirect = Redirect.split('/');

    // Jawad changes starts from here 

    let TempUrl = "";
    let ediClientID="";
    let pehrUser="";
    let cfg ="";
    //TempUrl=params.get("CHECKNUM")? "ERAView" :TempRedirect[3]=="AllPayerList"?"AllPayerList":"";
    TempUrl=(TempRedirect[3]=="AllPayerList" ||TempRedirect[3]=="AllPayerList.html" )
    
    ?
    "AllPayerList":
    params.get("CHECKNUM")
   
    ?
    "ERAView":
    params.has('cfg')
    
    ?
    "PEHRLOGIN":""

    TempRedirect = TempRedirect[0] + "//" + TempRedirect[2] + "/";
    if (TempUrl == "AllPayerList") {
      setwebPayerList(true);
    }
    else if (TempUrl == "ERAView") {
      setERAViewList(true);
    }
    else if (TempUrl == "PEHRLOGIN") {   
        // porceed with PEHR login if param found 
     
      cfg =new URLSearchParams(atob(params.get("cfg")));
      if (cfg.has("dataNotFound"))
      {
        setErrorSpan(true);
      }
      else 
      {
        setloginPEHR(true)
        ediClientID = cfg.get("edi_client_id");
        setediClientID(ediClientID);
        pehrUser = cfg.get("user_name");
        setPehrUserName(pehrUser);
      }
 
    }
  
    else if (showlogin && Redirect != TempRedirect) {
      window.location.replace(TempRedirect);
    }
    if (!UserProfile.getSeqnum() == '') {

      setshowlogin(false)
    }
    else {
      setshowlogin(true)
    }

  }, [showlogin])

  if (!webPayerList && !ERAViewList && !loginPEHR) {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {showlogin && <Route exact path="/" name="Login Page" render={props => <Login onchangestatus={(statestatus) => setshowlogin(statestatus)}  showSpan={errorSpan} />} />}
            {!showlogin && <Route path="/" name="Home" render={props => <TheLayout />} />}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
  else {
    if (ERAViewList) {
      return (
        <ERACustomPEHR />
      );
    }
    else if (loginPEHR) {
      return (
        <PEHRLogin ClientID={ediClientID} UserName={pehrUserName} />
      );
    }
    else {
      return (
        <KendoPayerList />
      );
    }
  }
}

export default App;