
import { func } from "prop-types";
import React from "react";
 
var UserProfile = (function() {
    var full_name = "";
    var Seqnum="";
   
    
      var setSeqnum = function(Seq_num) {
        Seqnum = Seq_num;  
        sessionStorage.setItem('Seq_num', Seq_num);   
        // Also set this in cookie/sessionStorage
      };
    var getSeqnum = function() {
      Seqnum= sessionStorage.getItem('Seq_num')
      return Seqnum;    // Or pull this from cookie/sessionStorage
 
     
    };
 
    var setName = function(name) {
        full_name = name;    
        sessionStorage.setItem('full_name', name);   
        // Also set this in cookie/sessionStorage
      };
    var getName = function() {
      full_name= sessionStorage.getItem('full_name')
        return full_name;    // Or pull this from cookie/sessionStorage
      };


      var setDashboardData = function(name) {
        full_name = name;    
        sessionStorage.setItem('DashboardData', name);   
        // Also set this in cookie/sessionStorage
      };
    var getDashboardData = function() {
      full_name= sessionStorage.getItem('DashboardData')
        return full_name;    // Or pull this from cookie/sessionStorage
      };
   
      
      var setVisitSeqNum = function(name) {
        full_name = name;    
        sessionStorage.setItem('VisitSeqNum', name);   
        // Also set this in cookie/sessionStorage
      };
    var getVisitSeqNum = function() {
      full_name= sessionStorage.getItem('VisitSeqNum')
        return full_name;    // Or pull this from cookie/sessionStorage
      };
      
      var setStart = function(name) {
        full_name = name;    
        sessionStorage.setItem('Start', name);   
        // Also set this in cookie/sessionStorage
      };
    var getStart = function() {
      full_name= sessionStorage.getItem('Start')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

      var setEnd = function(name) {
        full_name = name;    
        sessionStorage.setItem('End', name);   
        // Also set this in cookie/sessionStorage
      };
    var getEnd = function() {
      full_name= sessionStorage.getItem('End')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

      var setTotal = function(name) {
        full_name = name;    
        sessionStorage.setItem('total', name);   
        // Also set this in cookie/sessionStorage
      };
    var getTotal = function() {
      full_name= sessionStorage.getItem('total')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

      var setPayerListType = function(name) {
        full_name = name;    
        sessionStorage.setItem('type', name);   
        // Also set this in cookie/sessionStorage
      };
    var getPayerListType = function() {
      full_name= sessionStorage.getItem('type')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

    
      
      var setPayerListTab = function(name) {
        full_name = name;    
        sessionStorage.setItem('tab', name);   
        // Also set this in cookie/sessionStorage
      };
    var getPayerListTab = function() {
      full_name= sessionStorage.getItem('tab')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

      var setMuiTableStart = function(name) {
        full_name = name;    
        sessionStorage.setItem('MuiStart', name);   
        // Also set this in cookie/sessionStorage
      };
    var getMuiTableStart = function() {
      full_name= sessionStorage.getItem('MuiStart')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

      var setMuiTableEnd = function(name) {
        full_name = name;    
        sessionStorage.setItem('MuiEnd', name);   
        // Also set this in cookie/sessionStorage
      };
    var getMuiTableEnd = function() {
      full_name= sessionStorage.getItem('MuiEnd')
        return full_name;    // Or pull this from cookie/sessionStorage
      };

      var setCurrentPage = function(name) {
        full_name = name;    
        sessionStorage.setItem('CurrentPage', name);   
        // Also set this in cookie/sessionStorage
      };
    var getCurrentPage = function() {
      full_name= sessionStorage.getItem('CurrentPage')
        return full_name;    // Or pull this from cookie/sessionStorage
      };
      

      var setMuiDropDown = function(name) {
        full_name = name;    
        sessionStorage.setItem('MuiDropDown', name);   
        // Also set this in cookie/sessionStorage
      };
    var getMuiDropDown = function() {
      full_name= sessionStorage.getItem('MuiDropDown')
        return full_name;    // Or pull this from cookie/sessionStorage
      };
      

      var removeItem = function() {
        sessionStorage.removeItem("Start");
        sessionStorage.removeItem("End");
        sessionStorage.removeItem("total");
        sessionStorage.removeItem("type");
        sessionStorage.removeItem("tab");
        sessionStorage.removeItem("PayerName");
        sessionStorage.removeItem("PayerId");
        sessionStorage.removeItem("PayerFilter");
        // sessionStorage.removeItem("MuiStart");
        // sessionStorage.removeItem("MuiEnd");
        return 0
        };

        var removeProviderEnrollmentItem = function() {
          sessionStorage.removeItem("Start");
          sessionStorage.removeItem("End");
          sessionStorage.removeItem("ProviderEnrollmentTab");
          return 0
          };
        

        var DashboardItemsRemove = function() {
          sessionStorage.removeItem("isFromDashboard");
          
          return 0
          };


          
      

        var setReportData = function(name) {
          full_name = name;    
          sessionStorage.setItem('ReportData', name);   
          // Also set this in cookie/sessionStorage
        };
      var getReportData = function() {
        full_name= sessionStorage.getItem('ReportData')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        var setProviderEnrollmentType = function(name) {
          full_name = name;    
          sessionStorage.setItem('ProviderEnrollmentType', name);   
          // Also set this in cookie/sessionStorage
        };
      var getProviderEnrollmentType = function() {
        full_name= sessionStorage.getItem('ProviderEnrollmentType')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        
        var setProviderEnrollmentTab = function(name) {
          full_name = name;    
          sessionStorage.setItem('ProviderEnrollmentTab', name);   
          // Also set this in cookie/sessionStorage
        };
      var getProviderEnrollmentTab = function() {
        full_name= sessionStorage.getItem('ProviderEnrollmentTab')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        var setRejectionReason = function(name) {
          full_name = name;    
          sessionStorage.setItem('RejectionReason', name);   
          // Also set this in cookie/sessionStorage
        };
      var getRejectionReason = function() {
        full_name= sessionStorage.getItem('RejectionReason')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        
        var setWorkQueueCount = function(name) {
          full_name = name;    
          sessionStorage.setItem('WorkQueueCount', name);   
          // Also set this in cookie/sessionStorage
        };
      var getWorkQueueCount = function() {
        full_name= sessionStorage.getItem('WorkQueueCount')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        var setPayerName = function(name) {
          full_name = name;    
          sessionStorage.setItem('PayerName', name);   
          // Also set this in cookie/sessionStorage
        };
      var getPayerName = function() {
        full_name= sessionStorage.getItem('PayerName')
          return full_name;    // Or pull this from cookie/sessionStorage
        };

        var setPayerId = function(name) {
          full_name = name;    
          sessionStorage.setItem('PayerId', name);   
          // Also set this in cookie/sessionStorage
        };
      var getPayerId = function() {
        full_name= sessionStorage.getItem('PayerId')
          return full_name;    // Or pull this from cookie/sessionStorage
        };

        var setPayerFilter = function(name) {
          full_name = name;    
          sessionStorage.setItem('PayerFilter', name);   
          // Also set this in cookie/sessionStorage
        };
      var getPayerFilter = function() {
        full_name= sessionStorage.getItem('PayerFilter')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        
        var setClaimStatus = function(name) {
          full_name = name;    
          sessionStorage.setItem('ClaimStatus', name);   
          // Also set this in cookie/sessionStorage
        };
      var getClaimStatus = function() {
        full_name= sessionStorage.getItem('ClaimStatus')
          return full_name;    // Or pull this from cookie/sessionStorage
        };

        var setClaimDuration = function(name) {
          full_name = name;    
          sessionStorage.setItem('DurationStatus', name);   
          // Also set this in cookie/sessionStorage
        };
      var getClaimDuration = function() {
        full_name= sessionStorage.getItem('DurationStatus')
          return full_name;    // Or pull this from cookie/sessionStorage
        };

        var setFromDashboard = function(name) {
          full_name = name;    
          sessionStorage.setItem('isFromDashboard', name);   
          // Also set this in cookie/sessionStorage
        };
      var getFromDashboard = function() {
        full_name= sessionStorage.getItem('isFromDashboard')
          return full_name;    // Or pull this from cookie/sessionStorage
        };


        var setPayerEligibility = function(name) {
          full_name = name;    
          sessionStorage.setItem('PayerEligibility', name);   
          
        };
      var getPayerEligibility = function() {
        full_name= sessionStorage.getItem('PayerEligibility')
          return full_name;   
        };

        var setServiceEligibility = function(name) {
          full_name = name;    
          sessionStorage.setItem('ServiceEligibility', name);   
          
        };
      var getServiceEligibility = function() {
        full_name= sessionStorage.getItem('ServiceEligibility')
          return full_name;   
        };


        var setAllowSendToPM = function(name) {
          full_name = name;    
          sessionStorage.setItem('AllowSendToPM', name);   
          
        };
      var getAllowSendToPM = function() {
        full_name= sessionStorage.getItem('AllowSendToPM')
          return full_name;   
        };

        var setAllowRequeue = function(name) {
          full_name = name;    
          sessionStorage.setItem('AllowRequeue', name);   
          
        };
      var getAllowRequeue = function() {
        full_name= sessionStorage.getItem('AllowRequeue')
          return full_name;   
        };


        var setVisitDOS = function(name) {
          full_name = name;    
          sessionStorage.setItem('VisitDOS', name);   
          
        };
      var getVisitDOS = function() {
        full_name= sessionStorage.getItem('VisitDOS')
          return full_name;   
        };

        
        var setFollowSeqNum = function(name) {
          full_name = name;    
          sessionStorage.setItem('FollowSeqNum', name);   
          
        };
      var getFollowSeqNum = function() {
        full_name= sessionStorage.getItem('FollowSeqNum')
          return full_name;   
        };

        var setStatusForClient = function(name) {
          full_name = name;    
          sessionStorage.setItem('StatusForClient', name);   
          
        };
      var getStatusForClient = function() {
        full_name= sessionStorage.getItem('StatusForClient')
          return full_name;   
        };

        var setClaimHistoryStatus = function(name) {
          full_name = name;    
          sessionStorage.setItem('ClaimHistoryStatus', name);   
          
        };
      var getClaimHistoryStatus = function() {
        full_name= sessionStorage.getItem('ClaimHistoryStatus')
          return full_name;   
        };

    return {
      setClaimHistoryStatus:setClaimHistoryStatus,
      getClaimHistoryStatus:getClaimHistoryStatus,
      setStatusForClient:setStatusForClient,
      getStatusForClient:getStatusForClient,
      setFollowSeqNum:setFollowSeqNum,
      getFollowSeqNum:getFollowSeqNum,
      setVisitDOS:setVisitDOS,
      getVisitDOS:getVisitDOS,
      setAllowSendToPM:setAllowSendToPM,
      getAllowSendToPM:getAllowSendToPM,
      setAllowRequeue:setAllowRequeue,
      getAllowRequeue:getAllowRequeue,
      getName: getName,
      setName: setName,
      setSeqnum:setSeqnum,
      getSeqnum:getSeqnum,
      setDashboardData : setDashboardData,
      getDashboardData: getDashboardData,
      setVisitSeqNum:setVisitSeqNum,
      getVisitSeqNum:getVisitSeqNum,
      setStart: setStart,
      getStart:getStart,
      setEnd: setEnd,
      getEnd: getEnd,
      setTotal:setTotal,
      getTotal:getTotal,
      setPayerListType:setPayerListType,
      getPayerListType:getPayerListType,
      setPayerListTab:setPayerListTab,
      getPayerListTab:getPayerListTab,
      removeItem:removeItem,
      getReportData:getReportData,
      setReportData:setReportData,
      setProviderEnrollmentType:setProviderEnrollmentType,
      getProviderEnrollmentType:getProviderEnrollmentType,
      setProviderEnrollmentTab:setProviderEnrollmentTab,
      getProviderEnrollmentTab:getProviderEnrollmentTab,
      setRejectionReason:setRejectionReason,
      getRejectionReason:getRejectionReason,
      setWorkQueueCount:setWorkQueueCount,
      getWorkQueueCount:getWorkQueueCount,
      setPayerName:setPayerName,
      getPayerName: getPayerName,
      setPayerId:setPayerId,
      getPayerId:getPayerId,
      setPayerFilter:setPayerFilter,
      getPayerFilter:getPayerFilter,
      setMuiTableStart:setMuiTableStart,
      getMuiTableStart:getMuiTableStart,
      setMuiTableEnd:setMuiTableEnd,
      getMuiTableEnd:getMuiTableEnd,
      setClaimStatus:setClaimStatus,
      getClaimStatus:getClaimStatus,
      setFromDashboard: setFromDashboard,
      getFromDashboard:getFromDashboard,
      setCurrentPage:setCurrentPage,
      getCurrentPage:getCurrentPage,
      setMuiDropDown:setMuiDropDown,
      getMuiDropDown:getMuiDropDown,
      DashboardItemsRemove:DashboardItemsRemove,
      removeProviderEnrollmentItem:removeProviderEnrollmentItem,
      setPayerEligibility:setPayerEligibility,
      getPayerEligibility:getPayerEligibility,
      setServiceEligibility:setServiceEligibility,
      getServiceEligibility:getServiceEligibility,
      setClaimDuration:setClaimDuration,
      getClaimDuration:getClaimDuration,
      
    }
  
  })();
  
  export default UserProfile;