


export const request = {

    Get:{
        Login: "ediportal/api/v1/Login",
        Dashboard:"ediportal/api/v1/DapperDashboard/DapperDashboardRequest",
        WorkQueue:"ediportal/api/v1/DapperWorkQueue/DapperWorkQueueRequest",
        WorkQueueExport:"ediportal/api/v1/DapperWorkQueue/WorkQueueExportRequest",
        DailySubmission:"ediportal/api/v1/DapperDailySubmission/DapperDailySubmissionRequest",
        DailySubmissionDetail:"ediportal/api/v1/DapperDailySubmission/DailySubmissionDetailRequest",
        DailySubmissionExport:"ediportal/api/v1/DapperDailySubmission/DapperDailySubmissionExport",
        DailySubmissionDetailExport:"ediportal/api/v1/DapperDailySubmission/DapperDailySubmissionDetailExport",
        Claim:"ediportal/api/v1/DapperClaim/DapperClaimRequest",
        ClaimExport:"ediportal/api/v1/DapperClaim/DapperClaimExport",
        PayerClaim:"ediportal/api/v1/DapperClaimStatus/DapperGetPayerClaimRequest",
        InstantClaim:"ediportal/api/v1/DapperClaimStatus/DapperGetInstantClaimRequest",
        ClaimStatusHistory:"ediportal/api/v1/DapperClaimStatus/DapperGetClaimStatusHistory",
        ClaimStatusHistoryExport:"ediportal/api/v1/DapperClaimStatus/DapperClaimStatusHistoryExport",
        ClaimHistoryStatus:"ediportal/api/v1/DapperClaimStatus/DapperClaimHistoryStatus",
        GetClaimStatusDetail:"ediportal/api/v1/DapperClaimStatus/DapperGetClaimStatusDetail",
        ERA:"ediportal/api/v1/DapperERA/DapperERARequest",
        ERAExport:"ediportal/api/v1/DapperERA/DapperERAExport",
        ProviderEnrollment:"ediportal/api/v1/DapperProvEnrollment/DapperProvEnrollmentRequest",
        ProvEnrollmentExport:"ediportal/api/v1/DapperProvEnrollment/DapperProvEnrollmentExport",
        ProviderEnrollmentDetail:"ediportal/api/v1/DapperProvEnrollment/DapperProvEnrollmentDetailRequest",
        ProvEnrollmentDetailExport:"ediportal/api/v1/DapperProvEnrollment/DapperProvEnrollmentDetailExport",
        Report:"ediportal/api/v1/DapperReport/ReportsRequest",
        DenialCategory:"ediportal/api/v1/Reports/DenialCategoryRequest",
        SubmissionMonth:"ediportal/api/v1/Reports/SubmissionPayerRequest",
        TopRejection:"ediportal/api/v1/DapperReport/DapperReportRequest",
        PayerList:"ediportal/api/v1/DapperPayerList/DapperPayerListRequest",
        PayerListExport:"ediportal/api/v1/DapperPayerList/DapperPayerListExport",
        VisitDetail:"ediportal/api/v1/VisitDetail/VisitDetailRequest",
        SENDTOPM:"ediportal/api/v1/VisitDetail/SENDTOPM",
        REUQUEVISIT:"ediportal/api/v1/VisitDetail/REUQUEVISIT",
        CHECKALLICD:"ediportal/api/v1/VisitDetail/CHECKALLICD",
        CHECKICD:"ediportal/api/v1/VisitDetail/CHECKICD",
        CHECKALLCPT:"ediportal/api/v1/VisitDetail/CHECKALLCPT",
        CHECKCPT:"ediportal/api/v1/VisitDetail/CHECKCPT",
        ZIPCODEDETAIL:"ediportal/api/v1/VisitDetail/ZIPCODEDETAIL",
        UPDATEVISITDETAILTAB:"ediportal/api/v1/VisitDetail/UPDATEVISITDETAILTAB",
        RESOLVEFOLLOWUP:"ediportal/api/v1/VisitDetail/RESOLVEFOLLOWUP",
        VisitEligibility:"ediportal/api/v1/DapperEligibility/DapperGetVisitEligibilityRequest",
        PayerServiceEligibility:"ediportal/api/v1/DapperEligibility/DapperGetPayerServiceEligibilityRequest",
        InstantEligibility:"ediportal/api/v1/DapperEligibility/DapperGetInstantEligibilityRequest",
        TransactionHistory:"ediportal/api/v1/DapperEligibility/DapperGetTransactionEligibilityRequest",
        TransactionHistoryExport:"ediportal/api/v1/DapperEligibility/DapperTransactionHistoryExport",
        EligibilityTesting:"ediportal/api/v1/Eligibility",
        PracticeEHRSingleClick: "ediportal/api/v1/PEHRLogin",
    },
    Post:{
        Login: "ediportal/api/v1/RequestHandler",
        Dashboard: "ediportal/api/v1/RequestHandler",
        WorkQueue:"ediportal/api/v1/RequestHandler",
        DailySubmission:"ediportal/api/v1/RequestHandler",
        DailySubmissionDetail:"ediportal/api/v1/RequestHandler",
        Claim:"ediportal/api/v1/RequestHandler",
        ERA:"ediportal/api/v1/RequestHandler",
        ProviderEnrollment:"ediportal/api/v1/RequestHandler",
        Report:"ediportal/api/v1/RequestHandler",
        DenialCategory:"ediportal/api/v1/RequestHandler",
        SubmissionMonth:"ediportal/api/v1/RequestHandler",
        TopRejection:"ediportal/api/v1/RequestHandler",
        PayerList:"ediportal/api/v1/RequestHandler",
        PayerListExport:"ediportal/api/v1/RequestHandler",
    }
    
}

