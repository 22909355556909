import React from "react";
import { withStyles } from "@material-ui/core/styles";
import global from "src/containers/GlobalSettings/global";
import axios from 'src/containers/GlobalSettings/axios';
import ReactDOM from 'react-dom';
import Loader from "src/components/Loader/Loader";

import {
    CRow,
    CCol,
    CCard,
    CModalHeader,
    CModalTitle,
    CModalBody,

} from '@coreui/react'

import ShowPaymentDetail from "./VisitDetails/ShowPaymentDetail";

const defaultToolbarSelectStyles = {

};
var params = new URLSearchParams(window.location.search)
class ERACustomPEHR extends React.Component {

    state = {
        loader: true,
        modal: false,
        data: ""
    };

    componentDidMount(props, state) {

        let url = "ediportal/api/v1/ERA/ERAVIEWAgainstCheckNum"

        if (params.get('CHECKNUM') != "") {
            axios.get(`${url}?checkNum=${params.get('CHECKNUM')}&db=LiveDB`)

                .then(response => {
                    if (response) {
                        this.setState({
                            loader: false,
                            modal: true,
                            data: response.data[0].data
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        loader: false,
                        modal: false,
                        data: []
                    })
                    alert("No record found");
                    // console.log("Didmount error=>" + error);
                })
        }

    }

    render() {

        return (

            <>
                <div>
                    {this.state.loader ?
                        <div style={{ padding: "20% 0% 0% 43%" }}>
                            <Loader></Loader>
                        </div>
                        : ""}
                    {this.state.modal ? <CRow>
                        <CCol>
                            <CCard>

                                <CModalHeader style={{ background: "#2988E5" }}>
                                    <CModalTitle style={{ color: "white" }}>VIEW ERA</CModalTitle>
                                </CModalHeader>
                                {/* style={{ padding: "1% 0% 0% 20%" }} */}
                                <CModalBody style={{ alignSelf: "center" }}>
                                    <ShowPaymentDetail res={this.state.data} />
                                </CModalBody>
                            </CCard>
                        </CCol>
                    </CRow> : ""}
                </div>
            </>
        );
    }
}

export default withStyles(defaultToolbarSelectStyles, { name: "ERACustomPEHR" })(ERACustomPEHR);